.nav-element {
    color: black;
    padding: 2px 5px;
    border: 1px solid transparent;
    border-radius: 5px;
}

.nav-element-dark {
    color: white;
    padding: 2px 5px;
    border: 1px solid transparent;
    border-radius: 5px;
}

.nav-element-active {
    /* border: 1px solid transparent; */
    border-bottom: 1px solid gray;
    /* border-radius: 50%; */
}

.nav-element:hover {
    border: 1px solid darkblue;
    box-shadow: 0 0 5px black;
}

.nav-element-dark:hover {
    border: 1px solid white;
    box-shadow: 0 0 5px gold;
}

