.wrapper {
  max-width: 100vw;
  margin: 0;
  padding: 0;
}

.home-wrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  height: 100%;
  width: 100%;
}

.home-section {
  margin: 0;
  background-color: black;
  background: no-repeat center / 100% url('./assets/home_background.jpeg');
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100vh;
}

.about-section {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.portfolio-section {
  background: no-repeat center/80% url('./assets/portfolio-bg.jpeg');
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
.contact-section {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.header-container {
  display: flex;
  justify-content: center;
  color: lightgray;
  height: 10%;
  font-style: italic;
  background-color: black;
}

.carousel {
display: flex;
flex: 2;
align-items: center;
margin-bottom: 5vh;
}

.about-heading-container {
  height: auto;
  max-width: 100vw;
  border-bottom: 10px black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 5vw;
  margin-bottom: 3vh;
}

.about-content-container {
  display: flex;
  flex-direction: row-reverse;
  height: 70vh;
  width: 90vw;
  padding: 0 5vw;
  margin-bottom: 5vh;
}

.portfolio-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 90vw;
  padding: 0 5vw;
  margin-bottom: 5vh;
}

.image-container {
  height: 100%;
  /* margin-right: 7vw; */
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  
}

.text-card {
  height: 70vh;
  width: 60vw;
}

.about-text {
  padding: 20px;
  font-size: .95rem;
}

#family-photo {
  height: auto;
  max-height: 100%;
  border-radius: 50%;
}

.main-nav {
  color: white;
  margin: 1rem;
  border: 1px solid rgba(0,0,0,.8);
  border-radius: 10rem;
  background-color: rgba(0, 0, 0, .8);
  width: 125px;
  cursor: pointer;
}

.main-nav:hover {
  box-shadow: 0px 0px 10px black;
}

h1 {
  color: black;
  margin: 0;
  padding: 1rem;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
}

.profile-pic-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-pic {
  height: 125px;
  border-radius: 100%;
  border: 1px solid black;
}

.home-container {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-content {
  width: 30%;
  align-self: center;
}

.footer {
  display: flex;
  align-self: center;
  height: 10%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.img-link {
  height: 35px;
  margin: 10px;
}