.navBar {
  max-height: 5vh;
  min-height: 5vh;
  width: 90vw;
  background-color: rgba(255, 255, 255, .3);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

