/* .card-container-primary {
    background-color: rgba(255, 255, 255, .9);
    height: 60vh;
    width: 40vw;
    margin: 1vw;
    border-radius: 20px;
    border: 2px solid black;
    

} */
.card-container {
    background-color: rgba(255, 255, 255, .9);
    /* height: 25vh;
    width: 15vw; */
    margin: 1vw;
    border-radius: 20px;
    border: 1px solid black;
    /* box-shadow: 5px 5px 5px darkgray; */
}